<template>
  <VueRecaptcha    
    :sitekey="siteKey"
    :load-recaptcha-script="true"    
    @verify="handleSuccess"
    @error="handleError"
  ></VueRecaptcha>
</template>

<script>
// import { computed, defineComponent } from 'vue';
// // import defineComponent from 'vue'; 
// // import computed from 'vue'; 
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'ReCaptcha',
  components: {
    VueRecaptcha
  },
  emits: ['success','error'],
  computed: {
    siteKey() {
      return '6Lcy5_EfAAAAAKkZxrwnTw1uUfSnYOkRoxOpf5p7';
    },    
  },
  methods: {
    handleError() {
      this.$emit('error');            
    },
    handleSuccess(response) {
      this.$emit('success');
    }          
}  

  //setup() {
    // const siteKey = computed(() => {
    //   return 'yourSiteAPIKey';
    // });

    // const handleError = () => {
    //   // Do some validation
    // };

    // const handleSuccess = (response) => {
    //  // Do some validation
    // };

    // return {
    //   handleSuccess,
    //   handleError,
    //   siteKey,
    // };
  //}
};
</script>