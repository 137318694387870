<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-card-text class="text-center">
                    <!-- <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/logo.svg" alt="" />
                    </v-avatar>                                     -->
                    <img src="@/assets/images/massage-logo-w.png" alt="" />    
                    <v-form  v-if="!submitted" v-model="formValid">
                        <h5>{{$t("Signin.Forgot Password")}}</h5>
                        <h6 class="text--disabled font-weight-medium mb-10">
                        {{ $t("Signin.Enter Your Email Below") }}
                        </h6>
                        <v-text-field 
                            label="Email" 
                            v-model="email"
                            :rules="emailRules"      
                            :validate-on-blur="true"
                        />                
                        <v-card flat>
                            <ReCaptcha @success="notRobotSuccess" @error="notRobotError" class="g-recaptcha" />
                        </v-card>                        
                        <v-btn class="mb-4 text-capitalize" 
                            block 
                            color="primary" 
                            @click="requestReset" 
                            dark
                            :loading="loading"
                            
                        >{{$t("Signin.Request Password Reset")}}</v-btn>
                        <div class="d-flex justify-around flex-wrap">
                            <v-btn text small color="primary" to="/auth/login"
                                class="text-capitalize"
                                >&lt; {{ $t("Signin.Go To Login") }}</v-btn
                            >
                        </div>
                    </v-form>
                    <div v-else>
                        <h5>{{$t("Signin.Password Reset Email Sent")}}</h5>
                        <h6 class="text--disabled font-weight-medium mb-10">
                            {{ $t("Signin.You will receive email check your inbox") }}
                            <!-- You will receive an password reset e-mail shortly, <br/>
                            so check your inbox to complete the password reset process. -->
                        </h6>
                        <v-btn
                                class="text-capitalize"
                                text
                                small
                                color="primary"
                                to="/auth/login"
                                >{{ $t("Signin.Go To Login") }}</v-btn
                            >
                        <!-- <v-btn class="mb-4" block color="primary" to="/auth/signup" dark
                            >Go to Sign-In</v-btn> -->
                    </div>                    

                </v-card-text>
            </base-card>
        </div>
        <v-snackbar
            v-model="snackbar"
            :timeout="2000"
            top
            tile
            color="red accent-2"
            >
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
    
</template>
<script>
import api from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js"
import ReCaptcha  from "src/components/myrecaptcha.vue";

export default {
    name: 'SignUp',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Sign Up'
    },
    components: {
        ReCaptcha
    },
    data() {
        return {
            submitted: false,
            
            email: '',                                                
            invitationCode: '',

            formValid: true,
            isNotRobotConfirned: false,
            loading: false,
            snackbar: false,
            snackbarMessage: '',

            emailRules: [
                fieldNotEmpty,
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                (v) => fieldMinLength(v, 5)
            ],                  
        }
    },
    methods: {
        requestReset(event) {                  
            if(!this.formValid) {
                this.snackbarMessage = "Make sure you have entered a valid e-mail.";
                this.snackbar = true;
                return;
            }  
            if(!this.isNotRobotConfirned) {
                this.snackbarMessage = "Make sure you have checked the \"I'm not a robot\" button.";
                this.snackbar = true;
                return;
            }                               
            this.loading = true;          
            api.auth.forgotPass({                
                email: this.email,   
                lang: this.$root.$i18n.locale
            })
            .then((rsp)=>{                
                this.submitted = true;
            })
            .catch((error)=>{
                this.snackbarMessage = "There was an error during password reset. Please contact the administrator";
                this.snackbar = true;
            })
            .finally(()=>{
                this.loading = false;
            })            
        },
        notRobotSuccess: function() {
            this.isNotRobotConfirned = true;
        },
        notRobotError: function() {
            this.isNotRobotConfirned = false;            
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #f2f4f4 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
.g-recaptcha {
        display: inline-block;
    }
</style>
